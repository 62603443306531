import { Link } from "gatsby"
import * as React from "react"
import { useTranslation } from 'react-i18next'
import './toppage.module.css'
import * as Style from './toppage.module.css'

import linkbanner from '../images/linkbanner_5_b.png'


function TopPage() {
  const { t, i18n } = useTranslation()

  return (
    <div className={Style.update}>

      {/* TOPICS */}
      {/* いまTOPICSなし

      <h6 className={Style.bgnv}>{t('key122')}</h6>

      <p>{t('key123-1')}
        <a className="text-decoration-none mx-1" href={t('key123-2')} target="_blank" rel="noopener noreferrer">JIMTOF2024</a>
        {t('key123-3')}<br />{t('key123-3-2')}</p>
      <p>{t('key123-4')}</p>
      <div className={Style.ref}>
        <a className="text-decoration-none mx-4" href={t('key123-6')} target="_blank" rel="noopener noreferrer">{t('key123-5')}</a>
      </div><br />
      <p>{t('key123-7')}</p>
      <div className={Style.ref}>
        <a className="text-decoration-none mx-4" href={t('key123-9')} target="_blank" rel="noopener noreferrer">{t('key123-8')}</a>
      </div>

      <br />
      *}



      {/* GAZIRU 製品紹介動画 */}
      <h5>{t('key17')}</h5>
      <br />
      <div className="moviep">
        <div className="movie"><iframe loading="lazy" title="GAZIRU + Dinolite" width="757" height="426" src="https://www.youtube.com/embed/hSXelFIz_Uo?feature=oembed" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div><br />
      <br />

      {/* 事業内容 */}
      <h5>{t('key2')}</h5>
      <p>{t('key21')}</p>
      <h6>{t('key22')}</h6>
      <p>{t('key23')}</p>
      <p><Link className="text-decoration-none mx-1" to={t('key7')}>{t('key24')}</Link></p><br />

      {/* 導入事例 */}
      <h5>{t('key3')}</h5>
      <h6>{t('key35')}</h6>
      <p>{t('key25')}</p>
      <h6>{t('key124')}</h6>
      <p>{t('key125')}</p>
      <h6>{t('key26')}</h6>
      <p>{t('key27')}</p>
      <h6>{t('key28')}</h6>
      <p>{t('key29')}</p>
      <p><Link className="text-decoration-none mx-1" to={t('key8')}>{t('key24')}</Link></p><br />

      {/* お知らせ */}
      <h5>{t('key30')}</h5>

      {/*replacekey_notice_begin*/}
      {/* TOPICS的なもの */}
      

      <br />

      {/* お知らせの特別残しておくやつ */}

      <h6>{t('key31')}</h6>
      <p>{t('key32')}</p>
      <a className="text-decoration-none mx-1" href="https://co-hr-innovation.jp/" target="_blank" rel="noopener noreferrer">{t('key33')}</a><br />
      <a className="text-decoration-none mx-1" href="https://www.nikkei.com/article/DGXMZO61733720Q0A720C2TJ1000/" target="_blank" rel="noopener noreferrer">{t('key34')}</a>
      {/*replacekey_notice_end*/}
      {/* お知らせの特別残しておくやつ ここまで */}

      <br /><br />

      {/* お知らせの履歴 */}
      {/*insertkey_notice*/}

      <h6>{t('key126')}</h6>
      <p>{t('key123-1')} <a className="text-decoration-none mx-1" href={t('key108')} target="_blank" rel="noopener noreferrer">JIMTOF2024</a>
         {t('key123-3')}<br />{t('key123-4')}<br />{t('key127')}</p>

      <h6>{t('key121-1')}</h6>
      <p>{t('key121-2')}<br />{t('key121-3')}</p>
      <div className={Style.ref}>
        <span>{t('key14')}</span><br />
        <div className={Style.ref}>
          <a className="text-decoration-none mx-4" href="https://www.artizon.museum" target="_blank" rel="noopener noreferrer">{t('key121-4')}</a><br />
          <a className="text-decoration-none mx-5" href="https://www.artizon.museum/wp-content/uploads/2024/07/ATZ240725_A_J.pdf" target="_blank" rel="noopener noreferrer">{t('key121-5')}</a><br />
          <a className="text-decoration-none mx-4" href="https://artexhibition.jp/topics/news/20240726-AEJ2229865/" target="_blank" rel="noopener noreferrer">{t('key121-6')}</a>
        </div>
      </div><br />

      <h6>{t('key118-2')}</h6>
      <p>{t('key118-1')} {t('key118-3')}
        <a className="text-decoration-none mx-1" href="https://techshare.co.jp/innovation-week2023/" target="_blank" rel="noopener noreferrer">DOBOT User Conference 2023</a><br />
        {t('key84')}</p>

      <h6>{t('key115')}</h6>
      <p>{t('key117-1')} <a className="text-decoration-none mx-1" href={t('key116')} target="_blank" rel="noopener noreferrer">JAPAN INNOVATION DAY</a>2023{t('key117-2')}<br />{t('key84')}</p>

      <h6>{t('key106')}</h6>
      <p>{t('key107')} <a className="text-decoration-none mx-1" href={t('key108')} target="_blank" rel="noopener noreferrer">JIMTOF2022</a>{t('key109')}<br />{t('key84')}</p>

      <h6>{t('key102')}</h6>
      <p>{t('key103')} <Link className="text-decoration-none mx-1" to={t('key10')}>{t('key5')}</Link></p>

      <h6>{t('key13')}</h6>
      {t('key18')}
      <div className={Style.ref}>
        <span>{t('key14')}</span><br />
        <div className={Style.ref}>
          <a className="text-decoration-none mx-1" href="https://www.caica.jp/wp-content/uploads/2021/10/20211019_1_oshirase.pdf" target="_blank" rel="noopener noreferrer">{t('key15')}</a><br />
          <a className="text-decoration-none mx-1" href="https://www.caica-technologies.co.jp/wp-content/uploads/2021/10/20211019_2_CT.pdf" target="_blank" rel="noopener noreferrer">{t('key16')}</a><br />
        </div>
      </div><br />

      <h6>{t('key36')}</h6>
      <p>{t('key37')}<br />{t('key84')}</p>

      <h6>{t('key38')}</h6>
      <p>{t('key39')}<a className="text-decoration-none me-2" href="https://www.nikko-pb.co.jp/products/detail.php?product_id=5110" target="_blank" rel="noopener noreferrer">{t('key40')}</a>{t('key41')}</p>

      <h6>{t('key42')}</h6>
      <p><a className="text-decoration-none me-2" href="https://www.youtube.com/watch?v=hSXelFIz_Uo" target="_blank" rel="noopener noreferrer">{t('key60')}</a>{t('key61')}</p>

      <h6>{t('key43')}</h6>
      <p>{t('key62')}<a className="text-decoration-none mx-1" href="https://jpn.nec.com/event/210706ai/index.html" target="_blank" rel="noopener noreferrer">{t('key63')}</a>{t('key64')}<br />{t('key84')}</p>

      <h6>{t('key44')}</h6>
      <p><Link className="text-decoration-none me-2" to={t('key9')}>{t('key65')}</Link>{t('key66')}</p>

      <h6>{t('key45')}</h6>
      <p>{t('key67')}<br />{t('key86')}</p>

      <h6>{t('key46')}</h6>
      <p>{t('key68')}<a className="text-decoration-none mx-1" href="https://jpn.nec.com/event/210218smartfactory/index.html" target="_blank" rel="noopener noreferrer">{t('key69')}</a>{t('key70')}<br />{t('key84')}</p>

      <h6>{t('key47')}</h6>
      <p>{t('key71')}<a className="text-decoration-none mx-1" href="https://intrapreneurs-hub10.peatix.com/" target="_blank" rel="noopener noreferrer">{t('key72')}</a>{t('key73')}<br />{t('key85')}</p>

      <h6>{t('key48')}</h6>
      <p>{t('key74')}<br />{t('key84')}</p>

      <h6>{t('key49')}</h6>
      <p>{t('key75')}<a className="text-decoration-none mx-1" href="https://ascii.jp/elem/000/004/029/4029516/" target="_blank" rel="noopener noreferrer">ASCII.jp</a>{t('key76')}</p>

      <h6>{t('key50')}</h6>
      <p>{t('key77')}<a className="text-decoration-none mx-1" href="https://www.intel.co.jp/content/www/jp/ja/now/ai-park-online.html" target="_blank" rel="noopener noreferrer">{t('key78')}</a>{t('key79')}</p>

      <h6>{t('key51')}</h6>
      <p>{t('key80')}<a className="text-decoration-none" href="https://news.mynavi.jp/article/20200908-1289094/" target="_blank" rel="noopener noreferrer">{t('key81')}</a>,<a className="text-decoration-none ms-1" href="https://techable.jp/archives/137179" target="_blank" rel="noopener noreferrer">TECHABLE</a>,<a className="text-decoration-none ms-1" href="https://www.excite.co.jp/news/article/Dreaminnovation_vent_news_cTOlDnWvsE/" target="_blank" rel="noopener noreferrer">{t('key82')}</a>{t('key83')}</p>

      <h6>{t('key52')}</h6>
      <p>{t('key87')}<a className="text-decoration-none mx-1" href="https://prtimes.jp/main/html/rd/p/000000001.000062152.html" target="_blank" rel="noopener noreferrer">{t('key88')}</a></p>

      <h6>{t('key53')}</h6>
      <p>{t('key89')}<a className="text-decoration-none mx-1" href="https://jpn.nec.com/event/200819smartfactory/index.html" target="_blank" rel="noopener noreferrer">{t('key90')}</a>{t('key91')}</p>

      <h6>{t('key54')}</h6>
      <p>{t('key92')}<a className="text-decoration-none mx-1" href="https://co-hr-innovation.jp/" target="_blank" rel="noopener noreferrer">{t('key93')}</a>{t('key94')}</p>

      <h6>{t('key55')}</h6>
      <p>{t('key95')}</p>

      <h6>{t('key56')}</h6>
      <p>{t('key95')}</p>

      <h6>{t('key57')}</h6>
      <p><Link className="text-decoration-none me-2" to={t('key8')}>{t('key96')}</Link>{t('key66')}</p>

      {/*
      <h6>{t('key58')}</h6>
      <p>{t('key97')}</p>

      <h6>{t('key59')}</h6>
      <p>{t('key98')}</p>
      */}
      <hr />
      <br />
      <p>{t('key99')}<Link className="text-decoration-none mx-1" to={t('key11')}>{t('key100')}</Link>{t('key101')}</p>
    </div>
  )
}

export default TopPage